import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Ingredient, RECIPES } from './constants';
import { Button } from 'primereact/button';

import 'primeicons/primeicons.css';
import './Recipes.css';

import japaneseCurry from './japanese-curry.jpg';
import redCurryCoconutSoup from './red-curry-coconut-soup.jpg';
import breakfastBurritos from './breakfast-burritos.jpg';
const bgs: Record<string, any> = {
  'japanese-curry': japaneseCurry,
  'red-curry-coconut-soup': redCurryCoconutSoup,
  'breakfast-burritos': breakfastBurritos,
};

export const Recipes = () => {
  const { name } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!name || !Object.keys(RECIPES).includes(name)) {
      navigate('/');
    }
  }, [name, navigate]);
  const recipe = name ? RECIPES[name] : {};
  return (
    <div
      className='recipe-container'
      style={{
        backgroundImage: `url(${name ? bgs[name] : ''})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Helmet title={`${recipe?.title} recipe`} />
      <div
        className='hidden md:inline-block col-1 print-hide'
        style={{ width: '3%' }}
      ></div>
      <div className='col-11 md:col recipe-card'>
        <div className='text-center' style={{ marginBottom: 16 }}>
          <h1>{recipe?.title}</h1>
        </div>
        <div className='text-center'>
          <img alt={recipe?.title} className='recipe-img' src={`${name}.jpg`} />
        </div>
        <div className='description'>
          <div dangerouslySetInnerHTML={{ __html: recipe?.description }} />
        </div>
        <div className='grid'>
          <div className='col-12'>
            <h2 className='ingredients-header'>ingredients</h2>
          </div>
          <div
            className={
              recipe?.ingredients?.optional?.length > 0
                ? 'col-12 md:col-6'
                : 'col'
            }
          >
            <h3>required</h3>
            <div>
              <ul className='ingredient'>
                {recipe?.ingredients?.required?.map(
                  ({ ingredient, quantity, link, unit }: Ingredient) => (
                    <li key={ingredient}>
                      {link ? (
                        <>
                          {quantity} {unit}{' '}
                          <a
                            href={link}
                            target='_blank'
                            rel='noreferrer'
                            style={{
                              color: recipe?.accentColor,
                              textDecoration: 'none',
                            }}
                          >
                            {ingredient}
                          </a>
                        </>
                      ) : (
                        <>
                          {quantity} {unit} {ingredient}
                        </>
                      )}
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
          {recipe?.ingredients?.optional?.length > 0 && (
            <div className='col-12 md:col'>
              <h3>optional</h3>
              <div>
                <ul className='ingredient'>
                  {recipe?.ingredients?.optional?.map(
                    ({ ingredient, quantity, link, unit }: Ingredient) => (
                      <li key={ingredient}>
                        {link ? (
                          <a
                            href={link}
                            target='_blank'
                            rel='noreferrer'
                            style={{
                              color: recipe?.accentColor,
                              textDecoration: 'none',
                            }}
                          ></a>
                        ) : (
                          <span>
                            {quantity} {unit} {ingredient}
                          </span>
                        )}
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>

      <div
        className='hidden md:inline-block col-1 print-hide'
        style={{ width: '3%' }}
      ></div>
      <div className='col-11 md:col recipe-card'>
        <Button
          className='print-button print-hide'
          rounded
          outlined
          icon={<i className='pi pi-print' style={{ marginRight: 8 }} />}
          style={{
            display: 'inline-block',
            marginBottom: -32,
            marginLeft: 'auto',
          }}
          onClick={() => window.print()}
        >
          print recipe
        </Button>
        <div>
          <h2>directions</h2>
          <div className='directions'>
            <ol>
              {recipe?.directions?.map((step: string) => (
                <li key={step}>{step}</li>
              ))}
            </ol>
          </div>
        </div>
        {recipe?.notes?.length > 0 && (
          <div className='notes-container'>
            <h2>notes</h2>
            <div className='notes'>
              {recipe.notes.map((note: string) => (
                <div className='note' key={note}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: note.replace(/#######/g, recipe?.accentColor),
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <div
        className='hidden md:inline-block col-1 print-hide'
        style={{ width: '3%' }}
      ></div>
    </div>
  );
};
