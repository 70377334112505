export interface Ingredient {
  quantity: string;
  unit: string;
  ingredient: string;
  link?: string;
}

export const RECIPES: Record<any, any> = {
  blank: {
    title: '',
    accentColor: '',
    description: '',
    ingredients: {
      required: [
        {
          quantity: '',
          unit: '',
          ingredient: '',
          link: '',
        },
      ],
      optional: [],
    },
    directions: [],
    notes: [],
  },
  'japanese-curry': {
    title: 'japanese curry',
    accentColor: '#ff4c28',
    description:
      'a culinary game of curry telephone, japanese curry was inspired by english curry, which was inspired by indian curry. easy to make, and the perfect hearty cold-weather comfort food, this is one of my favorite meals of all time.<br /><br />' +
      "feel free to play around with this recipe and hone your own personalized perfect curry recipe, or simply use up any old vegetables lingering in your fridge before they go bad. this recipe is relatively traditional, but there's a lot of room for expirimentation and improvisation. have fun with it!",
    ingredients: {
      required: [
        {
          quantity: '1',
          unit: '3-4oz package',
          ingredient: 'japanese curry block',
          link: 'https://www.google.com/search?q=japanese+curry+block&tbm=isch',
        },
        { quantity: '1½', unit: 'tbsp', ingredient: 'oil or butter' },
        { quantity: '1', unit: 'medium', ingredient: 'onion' },
        { quantity: '1-3', unit: 'peeled', ingredient: 'carrots' },
        { quantity: '1-3', unit: 'stalk(s) of', ingredient: 'celery' },
        { quantity: '3-4', unit: 'medium-sized', ingredient: 'potatoes' },
        {
          quantity: '4',
          unit: 'cups',
          ingredient: 'stock or water (or any combination)',
        },
      ],
      optional: [
        {
          quantity: '1½',
          unit: 'lbs',
          ingredient: 'stewing beef/chicken thighs/shrimp',
        },
        { quantity: '2-3', unit: 'cloves', ingredient: 'garlic' },
        { quantity: '1', unit: 'tsp', ingredient: 'grated ginger' },
        { quantity: '1-2', unit: 'cup(s)', ingredient: 'mushrooms' },
        { quantity: '½', unit: 'grated', ingredient: 'apple' },
      ],
    },
    directions: [
      'start preparing rice first, leave to cook while preparing the rest of the curry',
      'peel the carrots, peel the potatoes if desired, grate ginger, grate/crush/mince garlic if used',
      'chop up the onion, carrots, celery, and potatoes and any other vegetables/mushrooms into bite-sized pieces',
      'heat up a bit of oil or butter in a dutch oven or pot, then sauté at medium heat and brown any meat, if used. This can be either left in the pot or removed before moving onto the next step, depending on the cooking time of the meat',
      'sauté the onions for a few minutes until translucent, add more oil/butter if needed',
      'add in the rest of the chopped/grated vegetables, excluding potatoes, cook for a few minutes to soften',
      'add in your water/stock and potatoes, along with any other liquid-y add-ins, bring to a boil and then reduce to a simmer. cook until potatoes are softened (can be 15-35 minutes depending on size). you can fish out and test larger pieces with a fork until they break easily to test doneness',
      'remove from heat, break apart curry blocks and add. stir thoroughly until fully dissolved. let sit for a few minutes to thicken and cool slightly before serving over rice',
    ],
    notes: [
      "some other additions i've seen: honey, ketchup, soy sauce, asian pear, peppers, sweet potatoes",
      "while it's traditional over rice, it can also be served on toast or over noodles, or however you can imagine it tasting good (we made it into a burrito once...)",
      'i\'ve always lived in a house with a rice cooker, so i don\'t know how to cook rice on the stove, <a href="https://www.justonecookbook.com/how-to-cook-rice/" rel="noreferrer" target="_blank" style="color: #######; text-decoration: none">here\'s a link for folks without a rice cooker</a>',
      'chicken thighs and stewing beef do well with a long cooking time, while shrimp, more tender cuts of beef, and chicken breast could overcook and should be removed after cooking & added back in near the end',
    ],
  },
  'red-curry-coconut-soup': {
    title: 'red curry coconut soup',
    accentColor: '#ff8400',
    description:
      'this thai-inspired soup has it all, a balance of salty, spicy, umami, sweet, and sour.',
    ingredients: {
      required: [
        {
          quantity: '1-2',
          unit: 'tbsp(s)',
          ingredient: 'thai red curry paste',
          link: 'https://www.google.com/search?q=thai+red+curry+paste&tbm=isch',
        },
      ],
      optional: [],
    },
    directions: [''],
    notes: [''],
  },
  'breakfast-burritos': {
    title: 'freezable breakfast burritos',
    accentColor: '#ff8400',
    description: '',
    ingredients: {
      required: [
        {
          quantity: '',
          unit: '',
          ingredient: '',
        },
      ],
      optional: [],
    },
    directions: [''],
    notes: [''],
  },
};
