import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import './App.css';
import { Links } from './scenes/Links/Links';
import { Recipes } from './scenes/Recipes/Recipes';
import Helmet from 'react-helmet';

const App = () => {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Links />,
    },
    {
      path: '/recipes/:name',
      element: <Recipes />,
    },
    {
      path: '*',
      element: <Navigate to='/' replace />,
    },
  ]);
  return (
    <div className='content'>
      <Helmet title='meltknuckles' />
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
