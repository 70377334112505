import { Grid, IconButton } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { SvgIconComponent } from '@material-ui/icons';
import { styled } from '@mui/material/styles';

import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import MailIcon from '@mui/icons-material/Mail';

import bg from './bg.gif';
import container from './container.gif';
import { ReactComponent as TumblrIcon } from './tumblr.svg';
import { ReactComponent as ItchIoIcon } from './itchio.svg';
import { ReactComponent as KoFiIcon } from './kofi.svg';

import './Links.css';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip arrow {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#cbf809',
    color: '#000000',
    fontSize: 14,
    border: '2px solid #232423',
    fontWeight: 'bold',
    fontFamily: `'Comfortaa', Arial, Helvetica, sans-serif`,
    userSelect: 'none',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#cbf809',
  },
}));

export const Links = () => {
  const icon = (
    name: string,
    MuiIcon: SvgIconComponent,
    link: string,
    custom = false
  ) => (
    <a href={link} target='_blank' rel='noreferrer'>
      <LightTooltip title={name}>
        <IconButton className='link-button'>
          <MuiIcon className={`link-icon ${custom ? 'custom-icon' : ''}`} />
        </IconButton>
      </LightTooltip>
    </a>
  );

  return (
    <Grid container className='App' style={{ backgroundImage: `url(${bg})` }}>
      <Grid item xs={11} sm='auto'>
        <div className='animatepop'>
          <div className='rock'>
            <Grid
              container
              className='link-container'
              style={{ backgroundImage: `url(${container})` }}
            >
              <Grid item>
                <h1>
                  {[
                    'm',
                    'e',
                    'l',
                    't',
                    'k',
                    'n',
                    'u',
                    'c',
                    'k',
                    'l',
                    'e',
                    's',
                  ].map((char, idx) => (
                    <span
                      key={idx}
                      style={{ '--i': idx + 1 } as React.CSSProperties}
                    >
                      {char}
                    </span>
                  ))}
                </h1>
                <p>gamedev • pixelsmith • lowpoly 3d dabbler</p>
                <Grid container justifyContent='space-between'>
                  {icon('email', MailIcon, 'mailto:meltknuckles@gmail.com')}
                  {icon(
                    'twitter',
                    TwitterIcon,
                    'https://twitter.com/meltknuckles'
                  )}
                  {icon(
                    'itch.io',
                    ItchIoIcon,
                    'https://meltknuckles.itch.io/',
                    true
                  )}
                  {icon(
                    'tumblr',
                    TumblrIcon,
                    'https://meltknuckles.tumblr.com/',
                    true
                  )}
                  {icon(
                    'instagram',
                    InstagramIcon,
                    'https://www.instagram.com/meltknuckles/'
                  )}
                  {icon(
                    'ko-fi',
                    KoFiIcon,
                    'https://ko-fi.com/meltknuckles',
                    true
                  )}
                  {/* {icon('back 2 the drawing board', ModeIcon)} */}
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};
